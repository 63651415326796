<template>
  <v-app v-if="$store.borrowerWebContent !== undefined">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import snContentService from '../../../services/snContentService'

export default {
  name: 'App',
  data () {
    return {
      store: this.$store
    }
  },
  created () {
    this.setUpBorrowerWebContent()
  },
  methods: {
    async setUpBorrowerWebContent () {
      const initialRoutes = [
        'login', 'homehub_sso', 'sso_login',
        'onboarding', 'signup', 'ask-for-email', 'register-account',
        'lo_not_found'
      ]
      let sac = location.pathname.split('/').slice(-1)[0]
      if (initialRoutes.includes(sac)) sac = ''
      const { data: { content } } = await snContentService.borrowerWebContent(sac)
      if (content) {
        this.store.borrowerWebContent = JSON.parse(content)
      }
    }
  }
}
</script>
